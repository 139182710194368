#loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: black;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

.loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: black;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}

* {
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

.m100 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}

.m200 {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
}

.m300 {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}

.m400 {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.m500 {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

.m600 {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}

.m700 {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
}

.m800 {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
}

.m900 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-coloumn {
    display: flex;
    flex-direction: column;
}

.center {
    align-items: center;
    justify-content: center;
}

.center-left {
    align-items: center;
    justify-content: left;
}

.text-center {
    text-align: center;
}

header {
    padding: 10px 20px 10px 20px;
}

.grey-text {
    color: #4D4D4D;
}

.pink-text {
    color: #FF4D4D;
}

p[id="title-text"] {
    font-size: 18px;
}

p[id="slogan-text"] {
    margin-top: -3px;
    font-size: 12px;
}

header > img {
    margin-right: 5px;
}

header {
    -webkit-box-shadow: 0px 5px 10px -1px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 5px 10px -1px rgba(0,0,0,0.2);
    box-shadow: 0px 5px 10px -1px rgba(0,0,0,0.2);
}

main {
    padding: 20px;
}

.min-w180 {
    min-width: 180px;
}

.black-button {
    margin-bottom: 5px;
    border: 1px solid black;
    border-radius: 8px;
    padding: 10px 20px 10px 20px;
    background-color: black;
    color: white;
}

.white-button {
    margin-bottom: 5px;
    border: 1px solid black;
    border-radius: 8px;
    padding: 10px 20px 10px 20px;
    background-color: white;
    color: black;
}

.section-break {
    margin-bottom: 60px;
}

.feature-list {
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #4D4D4D;
    margin-bottom: 20px;
}

.image-feature-list {
    margin-top: 20px;
    margin-bottom: 20px;
}

p[id="12px"] {
    font-size: 12px;
    margin-bottom: 50px;
}

.call-to-action > h2 {
    margin-bottom: 10px;
}

.founder-list {
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #4D4D4D ;
    margin-bottom: 20px;
}

.photo-profile {
    border-radius: 100%;
}

p[id="founder-name"] {
    font-size: 15px;
}

p[id="role-text"] {
    font-size: 12px;
}

p[id="media-sosial"] {
    font-size: 12px;
}

.media-sosial {
    padding: 10px;
}

.media-sosial > * {
    margin-left: 5px;
    margin-right: 5px;
}

footer {
    background-color: black;
}

.logo-footer {
    margin: 20px 0px 20px 20px;
    width: max-content;
    padding: 10px;
    border-radius: 5px;
    background-color: white;
}

img[id="logo-footer"] {
    margin-right: 5px;
}

.arrow-up {
    flex: 1;
}

.info-text > img {
    aspect-ratio: 1/1;
    max-width: 100%;
    max-height: 200px;
}

.footer-information {
    font-size: 12px;
    padding: 0px 20px 20px 20px;
    color: white;
}

.button-footer {
    color: white;
    padding: 5px;
    border: none;
    background-color: transparent;
}

.button-footer > img {
    margin-right: 5px;
}

nav {
    flex-wrap: wrap;
}

.nav-button {
    border: none;
    background-color: transparent;
}

.nav-wrap {
    flex: 1
}

.nav-text {
    text-align: center;
}

.center-right {
    align-items: right;
    justify-content: right;
}

ul {
    text-decoration: none;
}

li {
    text-decoration: none;
}

div[id="navigation-mobile"] {
    display: none;
}

div[id="navigation-mobile"].active {
    display: flex;
    flex-direction: column;
}

div[id="navigation-mobile"] > a {
    text-decoration: none;
    color: black;
    margin: 5px;
    border: 1px solid black;
    border-radius: 8px;
    padding: 10px 0px 10px 0px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    flex-direction: row;
}

div[id="navigation-mobile"] > a > p {
    margin-left: 10px;
    min-width: 120px;
}

div[id="navigation-desktop"] > a {
    text-decoration: none;
    color: black;
    margin: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    display: none;
}

div[id="navigation-desktop"] > a > p {
    margin-left: 5px;
    margin-right: 5px;
}

.pointer {
    cursor: pointer;
}

.div-100vh {
    margin-top: -55px;
    height: 100vh;
}

.google-button {
    width: 100%;
    background-color: white;
    border: 1px solid #E53935;
    padding: 10px 15px 10px 15px;
    border-radius: 8px;
}

.google-button > img {
    margin-right: 10px;
}

.header-button {
    border: 1px solid black;
    border-radius: 3px;
    background-color: transparent;
}

div[id="button-login-header"] {
    display: none;
}

.main-dvh100 {
    height: 100dvh;
}

.card-div {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}

.user-info {
    display: none;
}

.user-info > img {
    border-radius: 100%;
    border: 1px solid black;
    margin-bottom: 10px;
}

.user-info > p[id="username"] {
    font-size: 18px;
}

.user-info > p[id="email"] {
    font-size: 12px;
}

.textarea-container {
    margin-top: 20px;
}

.card-textarea {
    margin-top: 5px;
    width: 100%;
}

.card-textarea > input {
    flex: 1;
    border: 1px solid black;
    resize: none;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 0px 4px 4px 0px;
    border-left: none;
    padding-left: 5px;
}

.card-textarea > input[id="url-toko-edit"] {
    border-radius: 0px;
}

.card-textarea > p[id="subdomain"] {
    border-radius: 0px 4px 4px 0px;
    padding-left: 5px;
}

.card-textarea > img {
    padding: 5px;
    background-color: black;
    border-radius: 4px 0px 0px 4px;
}

.card-textarea > p {
    font-size: 12px;
    color: white;
    padding: 7.5px;
    padding-left: 0px;
    background-color: black;
}

.upload-button {
    color: white;
    width: 100%;
    border: 1px solid black;
    border-radius: 4px;
    padding: 5px;
    background-color: black;
}

main[id="dashboard-main"] {
    height: 100dvh;
}

input[id="choosePicture"] {
    display: none;
}

.toast {
    width: 100%;
}

.error-toast {
    padding: 10px 15px 10px 15px;
    display: none;
    flex-direction: row;
    align-items: center;
    border: 1px solid #DD3333;
    border-radius: 10px;
    transition: opacity 0.5s;
}

.success-toast {
    padding: 10px 15px 10px 15px;
    display: none;
    flex-direction: row;
    align-items: center;
    border: 1px solid #38842C;
    border-radius: 10px;
    transition: opacity 0.5s;
}

.text-toast {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
}

.title-toast {
    font-size: 16px;
}

.message-toast {
    font-size: 14px;
    margin-top: -2px;
}

.button-navigation-mobile {
    width: 100%;
    padding: 10px;
    background-color: white;
    border: 1px solid black;
    border-radius: 8px;
    font-size: 16px;
    margin-top: 5px;
}

.button-header {
    width: 100%;
    margin-top: 5px;
}

[contentEditable="true"] {
    border: none;
    outline: none;
}

.about-us {
    flex-direction: column;
}

.button-jumbotron-container {
    flex-direction: column;
}

.min-w220 {
    min-width: 220px;
}

.media-contact-container > div[class="m400"] > p {
    margin-left: 5px;
    width: 100%;
    height: 100%;
    color: white;
}

.media-contact-container {
    background-color: black;
    border: 1px solid black;
    width: 100%;
    border-radius: 8px;
}

.whatsapp {
    background-color: #38842C;
    border: 1px solid #38842C;
}

.instagram {
    background-color: #E1306C;
    border: 1px solid #E1306C;
}

.facebook {
    background-color: #1877F2;
    border: 1px solid #1877F2;
}

.gmail {
    background-color: #EA4335;
    border: 1px solid #EA4335;
}

.media-contact-container-image {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.media-contact-container-text {
    padding-left: 10px;
    font-size: 14px;
    text-align: left;
    color: white;
    flex: 1;
}

.media-contact > div {
    margin-top: 5px;
    margin-bottom: 5px;
}

.img {
    background-color: white;
}

.button-jumbotron-container {
    flex-direction: column;
}

.gmail-contact {
    width: 100%;
    text-align: left;
    padding-bottom: 15px;
    border-bottom: 1px solid grey;
}

.input-textarea-container {
    width: 100%;
}

.input-fix  {
    width: 100%;
    border: 1px solid grey;
    border-radius: 8px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.input-fix > input {
    border: none;
    width: calc(100% - 20px);
    margin: 10px;
    box-sizing: border-box;
}

.input-textarea-container > textarea {
    border: 1px solid grey;
    width: calc(100% - 20px);
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
}

.input-textarea-container > p {
    margin-bottom: 5px;
    width: 100%;
    text-align: left;
    font-size: 13px;
}

.media-social-contact {
    overflow: hidden;
    width: 100%;
}

.media-social-contact > div {
    margin-top: 5px;
    margin-bottom: 5px;
}

.about-us {
    flex-direction: column;
}

.about-us-text {
    text-align: center;
}

.about-us-text-container {
    margin-top: 10px;
}

.about-us-text > h2 {
    margin-top: 10px;
}

.about-us-text > p {
    font-size: 14px;
}

lite-youtube {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
    margin-bottom: 10px;
}

.iframe-about-us {
    width: calc(100dvw - 40px);
}

.cb-main {
    height: 100dvh;
}

.cb-text-parent {
    display: none;
    width: 100%;
}

.cb-text-container {
    border: 1px solid rgb(235, 235, 235);
    border-bottom: none;
    flex-direction: column;
    width: 100%;
}

.cb-text-left-container {
    padding: 10px;
    min-width: 220px;
    word-wrap: normal;
    background-color: rgb(235, 235, 235);
}

.cb-text-right-container {
    padding: 10px;
    flex: 1;
}

.cb-button {
    width: 100%;
    margin-top: 10px;
}

.cb-button > button {
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: 10px;
    color: white;
    border-radius: 4px;
    background-color: black;
    border: 1px solid black;
    flex: 1;
}

.cb-button > button[id="back-to-dashboard"] {
    margin-bottom: 10px;
}

.cb-button > button > img {
    margin-right: 10px;
}

@media only screen and (min-width: 1024px) {
    div[id="navigation-desktop"] > a {
        display: flex;
        flex-direction: row;
    }

    div[id="navigation-desktop"] {
        padding-left: 20px;
    }

    .nav-button {
        display: none;
    }

    div[id="jumbotron"] {
        display: flex;
        flex-direction: row-reverse;
    }

    img[id="jumbotron-img"] {
        height: 550px;
    }

    .feature-wrap {
        display: flex;
        flex-direction: row;
    }

    .feature-wrap > div {
        margin-left: 10px;
        margin-right: 10px;
    }

    .founder-wrap {
        display: flex;
        flex-direction: row;
    }

    .arrow-up {
        justify-content: right;
        padding-right: 20px;
    }

    div[id="button-login-header"] {
        display: flex;
    }

    main[id="register-login-main"] {
        flex-direction: row;
    }

    main[id="register-login-main"] > div {
        width: 50%;
    }

    main[id="dashboard-main"] {
        flex-direction: row;
    }

    main[id="dashboard-main"] > div {
        width: 100%;
        height: 100dvh;
        margin-top: 0 !important;
        overflow-y: scroll;
    }

    main[id="dashboard-main"] > div[id="div-1"] {
        overflow-y: hidden;
    }

    main[id="dashboard-main"] > div[id="div-2"] {
        justify-content: start;
    }

    .about-us {
        flex-direction: row;
    }

    .button-jumbotron-container {
        flex-direction: row;
    }

    .button-jumbotron-container > button[id="learn-more"] {
        margin-right: 5px;
    }

    .media-contact {
        flex-direction: row;
    }

    .media-contact > div {
        margin-right: 10px;
    }

    .gmail-contact {
        width: 50%;
        border: 1px solid black;
        border-radius: 8px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .media-social-contact {
        width: 50%;
    }

    .input-textarea-container {
        width: 80%;
    }

    .media-contact-container {
        width: 80%;
    }

    .founder-list {
        max-width: calc(50% - 20px);
        min-width: calc(50% - 55px);
    }

    lite-youtube {
        margin-bottom: 0px;
    }
    
    .about-us-text {
        text-align: left;
    }

    .about-us {
        flex-direction: row;
    }
    
    .about-us > div {
        max-width: 50%;
        min-width: 50%;
    }

    .about-us-text-container {
        width: 80%;
    }

    .iframe-about-us {
        width: 90%;
    }
    
    .cb-text-container {
        flex-direction: row;
    }

    .cb-text-left-container {
        max-width: 220px;
    }

    .cb-button {
        padding-top: 20px;
        flex-direction: row;
    }

    .cb-button > button[id="back-to-dashboard"] {
        margin-bottom: 0px;
        margin-right: 5px;
    }

    .cb-button > button[id="call-us"] {
        margin-left: 5px;
    }
}
